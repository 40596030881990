/**
 * 将系统金额(分)转换为显示金额(元)
 * @param {number} amount - 系统中的金额数值
 * @returns {string} - 转换后的金额显示字符串
 */
const systemToDisplayAmount = (amount) => {
    if (isNaN(amount)) {
      throw new Error('参数必须是数字');
    }
  
    const formattedAmount = (amount / 100).toFixed(2);
    return formattedAmount;
  };
  
  /**
   * 将显示金额(元)转换为系统金额(分)
   * @param {string} displayAmount - 显示的金额字符串
   * @returns {number} - 转换后的系统金额数值
   */
  const displayToSystemAmount = (displayAmount) => {
    const amount = parseFloat(displayAmount);
    if (isNaN(amount)) {
      throw new Error('参数必须是有效的金额字符串');
    }
  
    return Math.round(amount * 100);
  };
  
  export { systemToDisplayAmount, displayToSystemAmount };
  