import React, { useState, useEffect } from 'react';
import { Radio, Form, Input, Button, Spin, InputNumber } from 'antd';
import axios from 'axios';
import moment from 'moment';
import { baseUrl } from '../../config/apiConfig';
import { systemToDisplayAmount, displayToSystemAmount } from './../../utils/AmountUtil';

const PaymentForm = () => {
    const [form] = Form.useForm();
    const [merchantName, setMerchantName] = useState('...');
    const [loading, setLoading] = useState(false);
    const [amount, setAmount] = useState(0);
    const [merchantInfo, setMerchantInfo] = useState({});
    const [showTipModule, setShowTipModule] = useState(false);
    const [tipPercent, setTipPercent] = useState(0);
    const [tipAmount, setTipAmount] = useState(0);
    const [tipList, setTipList] = useState([]);
    const [customTip, setCustomTip] = useState(null);
    const [totalAmount, setTotalAmount] = useState(0);

    useEffect(() => {
        // Get merchantId from URL
        const urlParts = window.location.pathname.split('/');
        const id = urlParts[urlParts.length - 1];

        if (id) {
            setLoading(true);
            // Fetch merchant info
            axios.get(`${baseUrl}/merchant/info/${id}`)
                .then(response => {
                    const { abbreviatedCompanyName, ttipList } = response.data.data;
                    setMerchantName(abbreviatedCompanyName);
                    setMerchantInfo(response.data.data);
                    if (ttipList.length > 0) {
                        setShowTipModule(true);
                        setTipList(ttipList);
                    }
                })
                .catch(error => {
                    console.error('There was an error fetching the merchant info!', error);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, []);

    const onFinish = (values) => {
        setLoading(true);
        const { remarks } = values;
        const orderAmount = displayToSystemAmount(totalAmount.toFixed(2));
        const addPayPayload = {
            merchantId: merchantInfo.id,
            type: 'jsapi',
            orderAmount,
            productInformation: remarks,
            description: remarks || merchantInfo.abbreviatedCompanyName,
            tipAmount: displayToSystemAmount(tipAmount.toFixed(2)),
            subtotalAmount: displayToSystemAmount(amount.toFixed(2)),
        };

        //console.log("addPayPayload", addPayPayload);
        // Add payment record
        axios.post(`${baseUrl}/pay/add_pay`, addPayPayload)
            .then(response => {
                const payId = response.data.data;
                const fullUrl = `https://tomatopayment.com/order/${payId}`;
                const encodedUrl = encodeURI(fullUrl);
                window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx8e4a489ceca2488d&redirect_uri=${encodedUrl}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`;
            })
            .catch(error => {
                console.error('There was an error adding the payment record!', error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const onAmountChange = (e) => {
        const value = parseFloat(e.target.value);
        setAmount(isNaN(value) ? 0 : value);
        updateTipAmount(customTip !== null ? customTip : tipPercent, value);
    };

    const handleTipChange = (e) => {
        const value = e.target.value;
        setTipPercent(value);
        setCustomTip(null);
        updateTipAmount(value, amount);
    };

    const handleCustomTipChange = (value) => {
        setCustomTip(value);
        setTipPercent(null);
        updateTipAmount(value, amount);
    };

    const updateTipAmount = (tip, amount) => {
        const calculatedTipAmount = amount * (tip / 100);
        setTipAmount(isNaN(calculatedTipAmount) ? 0 : calculatedTipAmount);
        const calculatedTotalAmount = amount + calculatedTipAmount;
        setTotalAmount(isNaN(calculatedTotalAmount) ? 0 : calculatedTotalAmount);
    };

    return (
        <div style={{ maxWidth: 400, margin: '0 auto', padding: '20px', textAlign: 'center' }}>
            {loading && (
                <div style={styles.spinnerOverlay}>
                    <Spin />
                </div>
            )}

            <h2>Tomato Pay</h2>
            <h3>{merchantName}</h3>
            <Form form={form} layout="vertical" onFinish={onFinish}>
                <Form.Item
                    name="amount"
                    label="Amount (金额) (CAD)"
                    rules={[
                        { required: true, message: 'Please input the amount!' },
                        {
                            validator(_, value) {
                                if (!value || parseFloat(value) > 0) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('Amount must be greater than 0'));
                            },
                        },
                    ]}
                >
                    <Input
                        prefix="$"
                        type="number"
                        placeholder="0.00"
                        onChange={onAmountChange}
                    />
                </Form.Item>

                {showTipModule && (
                    <>
                        <Form.Item name="tipPercent" label="Tip Percentage (小费百分比) (%)">
                            <Radio.Group onChange={handleTipChange} value={tipPercent} disabled={customTip !== null}>
                                {tipList.map((tip, index) => (
                                    <Radio.Button key={index} value={tip.amount}>
                                        {tip.amount}%
                                    </Radio.Button>
                                ))}
                            </Radio.Group>

                            <InputNumber
                                type='number'
                                min={0}
                                max={100}
                                value={customTip}
                                onChange={handleCustomTipChange}
                                placeholder="自定义小费百分比"
                                style={{ width: '100%', marginTop: 7 }}
                                suffix="%"
                            />

                        </Form.Item>
                        <Form.Item>
                            <div style={{ textAlign: 'left' }}>
                                Tip Amount (小费金额) (CAD): ${tipAmount.toFixed(2)}
                            </div>
                        </Form.Item>
                    </>
                )}

                <Form.Item
                    name="remarks"
                    label="Remarks (备注)"
                    rules={[{ required: false, message: 'Please input remarks!' }]}
                >
                    <Input.TextArea placeholder="Please input remarks; 请给商家留言" />
                </Form.Item>
                <Form.Item>
                    <div style={{ fontWeight: 'bold' }}>
                        Total Amount (总金额) (CAD): ${totalAmount.toFixed(2)}
                    </div>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
                        NEXT (继续)
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

const styles = {
    spinnerOverlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9999,
    },
};

export default PaymentForm;
