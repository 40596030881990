import React from 'react';
import './WeChatPay.css'; 
class WeChatPay extends React.Component {
  componentDidMount() {
    // 初始化微信 JSAPI
    this.initWeChatJSAPI();
  }

  initWeChatJSAPI = () => {
    // TODO: 从后端获取微信支付参数
    // 示例参数，请替换为真实参数
    const paymentParams = {
      appId: 'wx8e4a489ceca2488d',
      timeStamp: '1705702207694',
      nonceStr: 'IY4UTyRzpMDPV2QnFu5ADfnP3ryhqjsf',
      package: 'prepay_id=wx2006100750165001d163e7cd3425270000',
      signType: 'RSA',
      paySign: 'm9sYFvpfnml0Hnl6ADT1S5TBm9pLdbyqsuS4193bLkhHNSwCIINFCM0gYb8M8v+rzsc7Iwk4IZwiWB0Ewddxp3CJ9Gg/Kf/3LiHALQuiOeXNPwDQo81LacGJH2ZTQ64Z0cyJ/AK9eqpGxc7WbDW548qOdExKHVwAVeF+F61AP8tEYOfSad0jzaw6lIXqq1XAnY9oPKmN853TLj0UA093nYsm1LtDwp2J/D/tx86Mtb6Ls7ptnNVtgx5BC3Zisvf+z3z7MuQdYSGlrbPVnjE7npsJD0ILow7CjKki3Ohrwgd3Eh1ifTUftA9LCbgWQ+UBZxu6606AX1lPh4NnkwaAeg==',
    };

    // 初始化微信 JSAPI
    window.wx.config({
      debug: false, // 是否开启调试模式
      appId: paymentParams.appId,
      timestamp: paymentParams.timeStamp,
      nonceStr: paymentParams.nonceStr,
      signType: paymentParams.signType,
      signature: paymentParams.paySign,
      jsApiList: ['chooseWXPay'], // 需要使用的微信支付 API
    });

    // 配置成功后执行支付操作
    window.wx.ready(() => {
      // 点击支付按钮后调用支付
      document.getElementById('payButton').addEventListener('click', () => {
        this.startWeChatPay(paymentParams);
      });
    });

    // 配置失败时的处理
    window.wx.error((res) => {
      console.error('WeChat JSAPI config error:', res);
    });
  };

  startWeChatPay = (paymentParams) => {
    // 调用微信支付 API
    window.wx.chooseWXPay({
      ...paymentParams,
      success: (res) => {
        // 支付成功的回调
        console.log('Payment success:', res);
      },
      cancel: (res) => {
        // 用户取消支付的回调
        console.log('Payment canceled:', res);
      },
      fail: (res) => {
        // 支付失败的回调
        console.error('Payment failed:', res);
      },
    });
  };

  render() {
    return (
      <div className="wechat-pay-container">
        <h2 className="wechat-pay-title">WeChat Pay</h2>
        <h3 className="amount">金额：0.01</h3>
        <button id="payButton" className="wechat-pay-button">
          支付
        </button>
      </div>
    );
  }
}
export default WeChatPay;
