// src/components/TomatoPayPayment.js
import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Radio, Spin, message } from 'antd';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

const TomatoPayPayment = () => {
    const location = useLocation();
    const { amount, transDate, remarks, code } = location.state || {};
    const [orderInfo, setOrderInfo] = useState(null);
    const [loading, setLoading] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState('WeChat Pay');
    const [email, setEmail] = useState('');
    const [responseData, setResponseData] = useState();
    const [merchantName, setMerchantName] = useState('Nogi Sushi');
    const [outTradeNo, setOutTradeNo] = useState('');

    useEffect(() => {
        console.log('Order information fetched:', amount, transDate, remarks, code);
        //alert('amount:' + amount + 'transDate:' + transDate + 'remarks:' + remarks + 'code:' + code);
        handleGetPayParamsClick();

    }, []);

    const handlePaymentMethodChange = e => {
        setPaymentMethod(e.target.value);
    };


    // 获取支付参数v1
    const handleGetPayParamsClick = () => {
        if (!amount || parseFloat(amount) <= 0) {
            alert('Please enter a valid amount.');
            return;
        }

        setLoading(true);
        // alert('amount:' + amount);
        const apiUrl = 'https://tomatopayment.com/api/pay/js_api_pay';
        const requestBody = {
            code: code,
            // amount: 10,
            amount: parseFloat(amount) * 100,
        };

        fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log('Response data:', data);
                // alert('data:' + JSON.stringify(data));
                setResponseData(data);

                setOutTradeNo(data.data.outTradeNo);

            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error);
                alert('There was a problem with the fetch operation: ' + error);
            })
            .finally(() => {
                setLoading(false);
            })

    }

    // 微信内H5调起支付
    const invokeWechatPaymentH5 = () => {

        const { timeStamp, package: packageValue, paySign, appid, signType, nonceStr } = responseData;

        //  alert('debug'+JSON.stringify(responseData));
        // 发送orderid，获取wx.chooseWXPay和wx.config所需的参数

        const paymentData = {
            appId: responseData.data.appid,
            timeStamp: responseData.data.timeStamp,
            nonceStr: responseData.data.nonceStr,
            package: responseData.data.package,
            signType: responseData.data.signType,
            paySign: responseData.data.paySign,
        };


        // 使用微信 JSAPI 进行支付
        window.WeixinJSBridge.invoke('getBrandWCPayRequest', paymentData, function (res) {
            if (res.err_msg === 'get_brand_wcpay_request:ok') {
                // 支付成功的逻辑
                alert('Payment successful!');
            } else {
                // 支付失败的逻辑
                alert('Payment failed: ' + res.err_msg);
            }
        });

    };

    const handleConfirmAndPay = () => {
        //微信内H5调起支付
        invokeWechatPaymentH5();
    };

    if (loading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <Spin />
            </div>
        );
    }

    return (
        <div style={{ maxWidth: 400, margin: '0 auto', padding: '20px' }}>
            <div style={{ textAlign: 'center' }}>
                <h2>Tomato Pay</h2>
                <h3>{merchantName}</h3>
            </div>
            <Form
                className="tomato-pay-form"
                name="tomato_pay_form"
                onFinish={handleConfirmAndPay}
            >

                <p>Order Number: {outTradeNo}</p>
                <p>Create Time: {transDate}</p>
                <p>Remarks: {remarks}</p>
                <p>Order Amount (CAD): ${amount}</p>
                <p>Total (CAD): ${amount}</p>

                {/* <Form.Item name="paymentMethod" label="Payment Method">
                <Radio.Group onChange={handlePaymentMethodChange} value={paymentMethod} defaultValue={'WeChat Pay'}>
                    <Radio value="WeChat Pay">WeChat Pay</Radio>
                    <Radio value="Alipay">Alipay</Radio>
                </Radio.Group>
            </Form.Item>

            <Form.Item name="email" label="Receiving E-receipt?" defaultValue={'myEmail@itcg.ca'}>
                <Input
                    type="email"
                    placeholder="Please input your email address"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                />
            </Form.Item> */}

                <Form.Item>
                    <Button type="primary" htmlType="submit" style={{ marginTop: 20 }}>
                        Confirm and Pay
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default TomatoPayPayment;
