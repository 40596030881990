import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';

const PaymentForm = () => {
    const [form] = Form.useForm();
    const [merchantName, setMerchantName] = useState('Nogi Sushi');
    const [loading, setLoading] = useState(false);
    const [amount, setAmount] = useState(0);  // State variable for amount
    const [code, setCode] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        // Replace the URL with the actual endpoint
        // axios.get('https://api.example.com/merchant')
        //     .then(response => {
        //         setMerchantName(response.data.name);
        //         setLoading(false);
        //     })
        //     .catch(error => {
        //         console.error('There was an error fetching the merchant name!', error);
        //         setLoading(false);
        //     });

        setCode(getCodeFromUrl());
    }, []);

    const onFinish = (values) => {
        console.log('Form values:', values);
        const { amount, remarks } = values;
        const transDate = moment().format('YYYY-MM-DD HH:mm:ss');
        navigate('/ConfirmOrderNogi', { state: { amount, transDate, remarks, code } });
    };

    const onAmountChange = (e) => {
        const value = parseFloat(e.target.value);
        setAmount(isNaN(value) ? 0 : value);
    };


    // 获取授权码code
    function getCodeFromUrl() {
        var url = window.location.search;
        var theRequest = new Object();
        if (url.indexOf("?") !== -1) {
            var str = url.substr(url.indexOf("?") + 1);
            var strs = str.split("&");
            for (var i = 0; i < strs.length; i++) {
                theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
            }
        }
        return theRequest.code || null;
    }

    if (loading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <Spin />
            </div>
        );
    }

    return (
        <div style={{ maxWidth: 400, margin: '0 auto', padding: '20px', textAlign: 'center' }}>
            <h2>Tomato Pay</h2>
            <h3>{merchantName}</h3>
            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
            >
                <Form.Item
                    name="amount"
                    label="Amount (金额) (CAD)"
                    rules={[{ required: true, message: 'Please input the amount!' }]}
                >
                    <Input
                        prefix="$"
                        type="number"
                        placeholder="0.00"
                        onChange={onAmountChange}
                    />
                </Form.Item>
                <Form.Item
                    name="remarks"
                    label="Remarks (备注)"
                    rules={[{ required: true, message: 'Please input remarks!' }]}
                >
                    <Input.TextArea placeholder="Please input remarks; 请给商家留言" />
                </Form.Item>
                <Form.Item>
                    <div style={{ fontWeight: 'bold' }}>
                        Total Amount (总金额) (CAD): ${amount.toFixed(2)}
                    </div>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
                        NEXT (继续)
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default PaymentForm;
