// App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';

import Home from './Home';
import About from './About';
import App2 from './App2';
import PaymentForm_backup from './PaymentForm_backup';
import WeChatPay from './WeChatPay';
import PaymentDetector from './PaymentDetector';
import PaymentForm from './components/PaymentForm';
import TomatoPayPayment from './components/TomatoPayPayment';
import PaymentFormNogi from './pages/PaymentFormNogi';
import ConfirmOrderNogi from './pages/ConfirmOrderNogi';
import PaymentformPage from './pages/PaymentForm/PaymentForm';
import ConfirmPayment from './pages/ConfirmPayment/ConfirmPayment';
import DebugPage from './pages/Debug/DebugPage';


function App() {
  const debug = false;


  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<PaymentformPage />} />
          <Route path="/:id" element={<PaymentformPage />} />
          <Route path="/confirmPay" element={<TomatoPayPayment />} />
          <Route path="/about" element={<About />} />
          <Route path="/app2" element={<App2 />} />
          <Route path="/PaymentForm" element={<PaymentForm />} />
          <Route path="/WeChatPay" element={<WeChatPay />} />
          <Route path="/PaymentDetector" element={<PaymentDetector />} />
          <Route path="/PaymentFormNogi" element={<PaymentFormNogi />} />
          <Route path="/ConfirmOrderNogi" element={<ConfirmOrderNogi />} />
          <Route path="/dev/:id" element={< PaymentformPage />} />
          <Route path="/order/" element={<ConfirmPayment />} />
          <Route path="/order/:payId" element={<ConfirmPayment />} />
          <Route path='/debug' element={<DebugPage />} />

        </Routes>

        {debug && (
          <div style={{ textAlign: 'center', marginTop: "300px" }}>
            <hr />
            <label style={{ margin: '10px', color: "red" }}>debug</label>
            <nav>
              <ul>
                <li>
                  <Link to="/">PaymentFormNogi</Link>
                </li>
                <li>
                  <Link to="/ConfirmOrderNogi">ConfirmOrderNogi</Link>
                </li>
                <li>
                  <Link to="/PaymentDetector">PaymentDetector</Link>
                </li>
              </ul>
            </nav>
          </div>
        )}

      </div>
    </Router>
  );
}

export default App;
