// src/components/TomatoPayPayment.js
import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Radio, Spin, message } from 'antd';
import axios from 'axios';
import './TomatoPayPayment.css'; // Import the CSS file

const TomatoPayPayment = () => {
    const [orderInfo, setOrderInfo] = useState(null);
    const [loading, setLoading] = useState(true);
    const [paymentMethod, setPaymentMethod] = useState('WeChat Pay');
    const [email, setEmail] = useState('');

    useEffect(() => {

        // Replace with your API endpoint
        //     axios.get('/api/order-info')
        //         .then(response => {
        //             setOrderInfo(response.data);
        //             setLoading(false);
        //         })
        //         .catch(error => {
        //             message.error('Failed to fetch order information');
        //             setLoading(false);
        //         });
        // }, []);


        // Simulate fetching order information with test data
        const testData = {
            orderNumber: '17174483226830513',
            createTime: '2024-06-03 17:00:36',
            remarks: 'W',
            orderAmount: 1.00,
            total: 1.00,
        };

        setTimeout(() => {
            setOrderInfo(testData);
            setLoading(false);
        }, 1000); // Simulate network delay
    }, []);

    const handlePaymentMethodChange = e => {
        setPaymentMethod(e.target.value);
    };

    const handleConfirmAndPay = () => {
        // Implement payment processing logic
        message.success('Payment processed successfully!');
    };

    if (loading) {
        return <Spin />;
    }

    return (
        <Form
            className="tomato-pay-form"
            name="tomato_pay_form"
            onFinish={handleConfirmAndPay}
        >
            <h1>Tomato Pay</h1>
            <p>Order Number: {orderInfo.orderNumber}</p>
            <p>Create Time: {orderInfo.createTime}</p>
            <p>Remarks: {orderInfo.remarks}</p>
            <p>Order Amount (CAD): ${orderInfo.orderAmount.toFixed(2)}</p>
            <p>Total (CAD): ${orderInfo.total.toFixed(2)}</p>

            <Form.Item name="paymentMethod" label="Payment Method">
                <Radio.Group onChange={handlePaymentMethodChange} value={paymentMethod}>
                    <Radio value="WeChat Pay">WeChat Pay</Radio>
                    <Radio value="Alipay">Alipay</Radio>
                </Radio.Group>
            </Form.Item>

            <Form.Item name="email" label="Receiving E-receipt?">
                <Input
                    type="email"
                    placeholder="Please input your email address"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                />
            </Form.Item>

            <Form.Item>
                <Button type="primary" htmlType="submit">
                    Confirm and Pay
                </Button>
            </Form.Item>
        </Form>
    );
};

export default TomatoPayPayment;
