import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Spin } from 'antd';
import axios from 'axios';

const PaymentForm = () => {
  const [form] = Form.useForm();
  const [merchantName, setMerchantName] = useState('Nogi Sushi');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Replace the URL with the actual endpoint
    axios.get('https://api.example.com/merchant')
      .then(response => {
        setMerchantName(response.data.name);
        setLoading(false);
      })
      .catch(error => {
        console.error('There was an error fetching the merchant name!', error);
        setLoading(false);
      });
  }, []);

  const onFinish = (values) => {
    console.log('Form values:', values);
  };

  if (loading) {
    return <Spin />;
  }

  return (
    <div style={{ maxWidth: 400, margin: '0 auto', padding: '20px', textAlign: 'center' }}>
      <h2>Tomato Pay</h2>
      <h3>{merchantName}</h3>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
      >
        <Form.Item
          name="amount"
          label="Amount (金额) (CAD)"
          rules={[{ required: true, message: 'Please input the amount!' }]}
        >
          <Input prefix="$" type="number" placeholder="0.00" />
        </Form.Item>
        <Form.Item
          name="remarks"
          label="Remarks (备注)"
          rules={[{ required: true, message: 'Please input remarks!' }]}
        >
          <Input.TextArea placeholder="Please input remarks; 请给商家留言" />
        </Form.Item>
        <Form.Item>
          <div style={{ fontWeight: 'bold' }}>Total Amount (总金额) (CAD): $0.00</div>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
            NEXT (继续)
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default PaymentForm;
