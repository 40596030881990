// ConfirmPayment.js
import React, { useState, useEffect } from 'react';
import { Form, Button, Spin, message } from 'antd';
import axios from 'axios';
import { baseUrl } from '../../config/apiConfig';
import moment from 'moment';

const TomatoPayPayment = () => {
    const [orderInfo, setOrderInfo] = useState({});
    const [loading, setLoading] = useState(false);
    const [responseData, setResponseData] = useState();
    const [merchantName, setMerchantName] = useState('...');

    useEffect(() => {
        const fetchOrderInfo = async (payId) => {
            try {
                const response = await axios.post(`${baseUrl}/pay/select_pay_info`, { id: payId });
                console.log('Response data:', response.data);
                setOrderInfo(response.data.data);
                setMerchantName(response.data.data.tmerchant.abbreviatedCompanyName);
            } catch (error) {
                console.error('There was an error fetching the order info!', error);
            }
        };

        const fetchPaymentParams = async (code, payId) => {
            try {
                const response = await fetch(`${baseUrl}/pay/js_api_pay_v2`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ code, payId }),
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                console.log('Response data:', data);
                setResponseData(data);
            } catch (error) {
                console.error('There was a problem with the fetch operation:', error);
                alert('There was a problem with the fetch operation: ' + error);
            }
        };

        const init = async () => {
            setLoading(true);
            const urlParts = window.location.pathname.split('/');
            const payIdValue = urlParts[urlParts.length - 1];
            const urlParams = new URLSearchParams(window.location.search);
            const codeValue = urlParams.get('code');

            await Promise.all([
                fetchOrderInfo(payIdValue),
                fetchPaymentParams(codeValue, payIdValue)
            ]);

            setLoading(false);
        };

        init();
    }, []);

    // 微信内H5调起支付
    const invokeWechatPaymentH5 = () => {
        const { timeStamp, package: packageValue, paySign, appid, signType, nonceStr } = responseData.data;

        const paymentData = {
            appId: appid,
            timeStamp: timeStamp,
            nonceStr: nonceStr,
            package: packageValue,
            signType: signType,
            paySign: paySign,
        };

        // 使用微信 JSAPI 进行支付
        window.WeixinJSBridge.invoke('getBrandWCPayRequest', paymentData, function (res) {
            if (res.err_msg === 'get_brand_wcpay_request:ok') {
                alert('Payment successful!');
            } else {
                alert('Payment failed: ' + res.err_msg);
            }
        });
    };

    const handleConfirmAndPay = () => {
        invokeWechatPaymentH5();
    };

    return (
        <div style={{ maxWidth: 400, margin: '0 auto', padding: '20px', position: 'relative' }}>
            {loading && (
                <div style={styles.spinnerOverlay}>
                    <Spin />
                </div>
            )}
            <div style={{ textAlign: 'center' }}>
                <h2>Tomato Pay</h2>
                <h3>{merchantName}</h3>
            </div>
            <Form
                className="tomato-pay-form"
                name="tomato_pay_form"
                onFinish={handleConfirmAndPay}
            >
                <p>Order Number: {orderInfo.vendorOrderNo}</p>
                <p>Create Time: {moment(orderInfo.createTime).format('YYYY-MM-DD HH:mm:ss')}</p>
                <p>Remarks: {orderInfo.productInformation}</p>
                <p>Order Amount (CAD): ${(parseFloat(orderInfo.orderAmount) / 100).toFixed(2)}</p>
                <p>Total (CAD): ${(parseFloat(orderInfo.orderAmount) / 100).toFixed(2)}</p>

                <Form.Item>
                    <Button type="primary" htmlType="submit" style={{ marginTop: 20 }}>
                        Confirm and Pay
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

const styles = {
    spinnerOverlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9999,
    },
};

export default TomatoPayPayment;
